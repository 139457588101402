import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { MaterialModule } from '@app/material.module';
import { RegistrationRoutingModule } from './registration-routing.module';
import { RegistrationComponent } from './registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicImageModule } from '@app/dynamic-image/dynamic-image.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RegisterFormModule } from '@app/register-form/register-form.module';
import { WebsiteFragmentsModule } from '@app/website_fragments/website-fragments.module';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    MaterialModule,
    RegistrationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicImageModule,
    RegisterFormModule,
    MatFormFieldModule,
    MatInputModule,
    WebsiteFragmentsModule,
  ],
  declarations: [RegistrationComponent],
  exports: [RegistrationComponent],
})
export class RegistrationModule {}
