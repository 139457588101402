/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { environment } from '@env/environment';
import { AppModule } from '@app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
  enableProdMode();
} else {
  // devTools();
}

// bootstrapApplication(AppModule).catch((err) => console.error(err));

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// bootstrapApplication(AppComponent, appConfig)
//   // eslint-disable-next-line no-console
//   .then(() => console.log('Application started'))
//   .catch((err: unknown) => console.error(err));
