import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CredentialsService } from '@app/auth';
import { environment } from '@env/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private credentialsService = inject(CredentialsService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !request ||
      !request.url ||
      (request.url.startsWith('http') && !(environment.serverUrl && request.url.startsWith(environment.serverUrl)))
    ) {
      return next.handle(request);
    }

    const credentials = this.credentialsService.credentials;
    if (credentials != null) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + credentials.token,
        },
      });
    }
    return next.handle(request);
  }
}
