import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { WithdrawalProductType } from '@generated/generatedEntities';
import { CalculatorIntegrationActions } from '@app/calculators/integrations/state/calculator-integration.actions';

export const calculatorIntegrationFeatureKey = 'calculatorIntegration';

export interface State {
  enabledModules: WithdrawalProductType[];
}

export const initialState: State = {
  enabledModules: [],
};

export const reducer = createReducer(
  initialState,
  immerOn(CalculatorIntegrationActions.resetStore, (draft: State, data) => {
    draft = { ...initialState };
  }),
  immerOn(CalculatorIntegrationActions.calculatorIntegrations, (state) => state),
  immerOn(CalculatorIntegrationActions.calculatorIntegrationsSuccess, (state, action) => state),
  immerOn(CalculatorIntegrationActions.calculatorIntegrationsFailure, (state, action) => state),
  immerOn(CalculatorIntegrationActions.addModules, (state, action) => {
    if (action.modules.length > 0) {
      state.enabledModules.push(...action.modules);
      state.enabledModules = Array.from(new Set(state.enabledModules));
    }
    return state;
  }),
  immerOn(CalculatorIntegrationActions.removeModules, (state, action) => {
    if (action.modules.length > 0) {
      const set = new Set(state.enabledModules);
      action.modules.forEach((module) => set.delete(module));
      state.enabledModules = Array.from(set);
    }
  }),
);

export const calculatorIntegrationFeature = createFeature({
  name: calculatorIntegrationFeatureKey,
  reducer,
});
