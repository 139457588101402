import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  provideTransloco,
} from '@jsverse/transloco';
import { inject, Injectable, NgModule, Optional, SkipSelf } from '@angular/core';
import { environment } from '../environments/environment';
import { delay, map, mapTo } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);
  langScopes: Set<string> = new Set();

  // standard loader - this is still here for reference purpose.
  // getTranslation(lang: string) {
  //   return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  // }

  getTranslation(langPath: string) {
    const paths = langPath.split('/');
    let multipleLoader = true;
    let splittedMultiplePaths: string[];
    let multiplePaths: string = '';
    let activeLang: string;
    if (paths.length > 1) {
      [multiplePaths, activeLang] = paths;
      splittedMultiplePaths = multiplePaths.split(',');
    } else {
      activeLang = langPath;
      multipleLoader = false;
      splittedMultiplePaths = [];
    }

    let obs: Observable<Translation>[] = [];
    if (multipleLoader) {
      splittedMultiplePaths.forEach((path) => {
        //Transloco lazy loading style would be this one
        // obs.push(this.http.get<Translation>(`/assets/i18n/${path}/${ activeLang}.json`));
        /** when changed keep in mind that fetching data due to api interceptor might be broken on prod builds!
         * {@link ApiPrefixInterceptor}
         */
        obs.push(this.http.get<Translation>(`/assets/i18n/${activeLang}/${path}.json`));
        this.langScopes.add(path);
      });
    } else {
      obs.push(this.http.get<Translation>(`/assets/i18n/${activeLang}.json`));
    }
    // console.warn('translations',splittedMultiplePaths);
    return forkJoin([...obs]).pipe(
      map(([translation, vendor]) => {
        return { ...translation };
      }),
    );
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['de-CH', 'fr-CH', 'it-CH'],
        defaultLang: 'de-CH',
        fallbackLang: 'de-CH',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        missingHandler: {
          allowEmpty: true,
        },
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
  ],
})
export class TranslocoRootModule {
  constructor(@Optional() @SkipSelf() translocoRootModule: TranslocoRootModule) {
    if (translocoRootModule) {
      throw new Error('You should import core module only in the root module');
    }
  }
}
