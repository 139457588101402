import { Title } from '@angular/platform-browser';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '@app/auth/login.component';
import { LocationStrategy, ViewportScroller } from '@angular/common';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { UserDTO, WithdrawalProductType } from '@generated/generatedEntities';
import { MatMenuTrigger } from '@angular/material/menu';
import { GlobalModalService } from '@app/core/services/global_modal/global-modal.service';
import { UserService } from '@app/core/services/User/user.service';
import { debounceTime, take } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { ScrollService } from '@app/calculators/services/scroller-service/scroll.service';

const log = new Logger('Header');
@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['global', 'products'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HeaderComponent implements OnInit, AfterViewInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private titleService = inject(Title);
  private authenticationService = inject(AuthenticationService);
  private credentialsService = inject(CredentialsService);
  private dialog = inject(MatDialog);
  public url = inject(LocationStrategy);
  private translocoService = inject(TranslocoService);
  private cdr = inject(ChangeDetectorRef);
  private globalModalService = inject(GlobalModalService);
  private viewportScroller = inject(ViewportScroller);
  private _scrollService = inject(ScrollService);
  private userService = inject(UserService);
  @Input() sidenav!: MatSidenav;
  name!: string;
  color!: string;
  user: UserDTO | null = null;
  licenseIssue: WithdrawalProductType[] = [];
  @ViewChildren(MatMenuTrigger) trigger!: QueryList<MatMenuTrigger>;
  activeLang!: string;
  makeDarker: boolean = false;

  ngOnInit() {
    this.init();
    this.setHeaderDark();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    // this.isInStateOfAnyModule();
  }

  init() {
    this.cdr.detectChanges();
    this.activeLang = this.translocoService.getActiveLang();
    this.authenticationService.getAuthenticationState().subscribe({
      next: (value) => {
        this.user = value;
        // log.info(value);
        if (this.user) {
          this.user.authorities.indexOf('ROLE_ADMIN') > -1 ? (this.isAdminBool = true) : (this.isAdminBool = false);
          this.licenseIssue = this.user?.withdrawalProductTypes;
        }
      },
    });
  }

  setHeaderDark() {
    this._scrollService
      .getScrollY()
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe({
        next: (scrollY: number) => {
          scrollY > 0 ? (this.makeDarker = true) : (this.makeDarker = false);
        },
        error: (err: any) => {
          log.error('error setting darkmode: ', err);
        },
      });
  }

  openMenu(event: any) {
    this.trigger.toArray().forEach((item: MatMenuTrigger) => {
      item.closeMenu();
      if (item.menuData['trigger'] == event.currentTarget.getAttribute('data-value')) {
        item.openMenu();
      }
    });
  }

  Logout() {
    console.log('successfully logout');
    sessionStorage.clear();
    this.router.navigateByUrl('/landing-page');
  }

  logout() {
    this.authenticationService
      .logout()
      .pipe()
      .subscribe({
        next: (x) => {
          this.router.navigate([this.route.snapshot.queryParams['redirect'] || ''], { replaceUrl: true });
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          this.router.navigate([this.route.snapshot.queryParams['redirect'] || ''], { replaceUrl: true });
        },
      });
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '600px',
      // data: { name: this.name, color: this.color }

      // panelClass: 'custom-modalbox',
      closeOnNavigation: true,
    });
    // dialogRef.afterClosed().subscribe((res) => {
    //   console.log('response of pop up ', res);
    //   this.isLogin = res;
    // });
    // this.router.events.subscribe(() => {
    //   dialogRef.close();
    // });

    // if (dialogRef) {
    //   dialogRef.close();
    // }
  }

  contactDTmModal(modul: any) {
    this.globalModalService.contactDtmModal({ modul: modul });
  }

  redirectHome() {
    this.router.navigate(['']);
  }

  isAdminBool: boolean = false;
  isLoggedIn() {
    return this.credentialsService.isAuthenticated();
  }

  showHeader(): boolean {
    // Get the current URL path
    const currentUrl = this.router.url;

    // Check if the URL path contains form and public
    return !(currentUrl.includes('-form') && currentUrl.includes('public-'));
  }

  languageChange(language: string) {
    console.log('language change', language);
    this.activeLang = language;
    this.translocoService.setActiveLang(language);
    if (this.authenticationService.isAuthenticated()) {
      this.userService
        .updateUserLanguage(language)
        .pipe(take(1))
        .subscribe({
          next: (value) => {
            console.warn('language stored', value);
          },
          error: (err) => {
            Sentry.addBreadcrumb({ message: `could not store language for userId: ${this.user?.id}`, level: 'error' });
            Sentry.captureException(err);
          },
        });
    }
  }

  isInStateOfAnyModule() {
    return (
      this.isInStateProcessFormBuildingCosts() ||
      this.isInStateRebuildForm() ||
      this.isInStateOperatingCostsForm() ||
      this.isInStateLccForm() ||
      this.isInStateBuildingCostsRealestates() ||
      this.isInStateBuildingCostsRealestatesReference()
    );
  }

  isInStateOperatingCostsForm() {
    return this.url.path().includes('operational-form');
  }

  isInStateProcessFormBuildingCosts() {
    return (
      this.url.path().includes('process-form/input') &&
      this.licenseIssue &&
      this.licenseIssue.indexOf('BUILDING_COSTS_AND_DATES') > -1
    );
  }

  isInStatePlanningFoundation() {
    return (
      this.url.path().includes('planning-foundation-form/input') &&
      this.licenseIssue &&
      this.licenseIssue.indexOf('BUILDING_COSTS_AND_DATES') > -1
    );
  }

  isInStateRebuildForm() {
    return this.url.path().includes('rebuild-form');
  }

  isInStateLccForm() {
    return this.url.path().includes('lcc-form');
  }
  isInStateLcaForm() {
    return this.url.path().includes('lca-form');
  }
  isInStateKv2BIM() {
    return this.url.path().includes('kv-to-bim');
  }

  isInStateEffortPlannerForm() {
    return this.url.path().includes('effort-planner-form');
  }

  isInStateBuildingCostsRealestates() {
    return this.url.path() === '/building-costs/realestates';
  }

  isInStateBuildingCostsRealestatesReference() {
    return this.url.path() === '/building-costs/realestates-reference';
  }

  iSshowInsuranceGvbs() {
    return this.url.path() === 'gvbs/input';
  }
  iSshowSmallBuildingGvbs() {
    return this.url.path() === 'small-building-gvbs/input';
  }

  isInStateInsuranceValues() {
    return (
      this.isInStateSmallBuildingValues() ||
      this.isInStateDamageCosts() ||
      this.isInStateInsuranceValuesRealestates() ||
      this.isInStateInsuranceValuesRealestatesReference() ||
      this.isInStateProcessFormInsuranceValues()
    );
  }
  isInStateDamageCosts() {
    return (
      // this.isInStateInsuranceValuesRealestates() ||
      // this.isInStateInsuranceValuesRealestatesReference() ||
      this.isInStateDamageForm()
    );
  }

  isInStateInsuranceValuesRealestates() {
    return this.url.path() === '/insurance/realestates';
  }

  isInStateInsuranceValuesRealestatesReference() {
    return this.url.path() === '/insurance/realestates-reference';
  }
  isInStateSmallBuilding() {
    return this.url.path() === 'landing_page_agv';
  }
  isInStateSmallBuildingValues() {
    return this.url.path().includes('agv-small-building-form/input');
  }

  showInsuranceValuesFn() {
    return !this.showInsuranceValues();
  }

  showSmallBuildingFn() {
    return !this.showSmallBuilding();
  }

  showDamageCostsFn() {
    return !this.showDamageCosts();
  }

  isInStateProcessFormInsuranceValues() {
    return this.url.path().includes('insurance-form/input');
  }

  isInStateDamageForm() {
    return this.url.path().includes('/damage-form/input');
  }

  isInStateAccuracy() {
    return this.url.path().includes('accuracy');
  }

  isInStateAccuracyOperatingCosts() {
    return this.url.path().includes('accuracy_operating_costs');
  }

  hasAnyInsuranceModule() {
    return this.authenticationService.hasAnyModule('BUILDING_INSURANCE_VALUES');
  }
  goToAgv() {
    this.router.navigate(['/agv']);
  }

  hasAnyBuildingCostsModule() {
    return this.authenticationService.hasAnyModule('BUILDING_COSTS_AND_DATES');
    // return Principal.hasAnyBuildingCostsModule();
  }
  showBuildingCosts() {
    return (
      this.isAdminBool ||
      (this.isDefined(this.licenseIssue) && this.licenseIssue.indexOf('BUILDING_COSTS_AND_DATES') > -1)
    );
  }
  showInsuranceValues() {
    return (
      this.isAdminBool ||
      (this.isDefined(this.licenseIssue) && this.licenseIssue.indexOf('BUILDING_INSURANCE_VALUES') > -1)
    );
  }
  showOperatingCosts() {
    return this.isAdminBool || (this.isDefined(this.licenseIssue) && this.licenseIssue.indexOf('OPERATING_COSTS') > -1);
  }
  showRebuildCosts() {
    return this.isAdminBool || (this.isDefined(this.licenseIssue) && this.licenseIssue.indexOf('REBUILD_COSTS') > -1);
  }
  showSmallBuilding() {
    return (
      this.isAdminBool || (this.isDefined(this.licenseIssue) && this.licenseIssue.indexOf('SMALL_BUILDING_AGV') > -1)
    );
  }
  showDamageCosts() {
    return this.isAdminBool || (this.isDefined(this.licenseIssue) && this.licenseIssue.indexOf('DAMAGE_COSTS') > -1);
  }
  showInsuranceGvbs() {
    return (
      this.isAdminBool || (this.isDefined(this.licenseIssue) && this.licenseIssue.indexOf('INSURANCE_VALUES_GVBS') > -1)
    );
  }
  showSmallBuildingGvbs() {
    return (
      this.isAdminBool || (this.isDefined(this.licenseIssue) && this.licenseIssue.indexOf('SMALL_BUILDING_GVBS') > -1)
    );
  }
  showInsuranceRealEstatesOverview() {
    return (
      this.showInsuranceGvbs() ||
      (this.isDefined(this.licenseIssue) && this.user?.organisationRole?.includes('ORG_OWNER'))
    );
  }

  isGvbsAdminOrHasOtherCalculator() {
    return (
      this.isDefined(this.licenseIssue) &&
      ((this.licenseIssue.indexOf('INSURANCE_VALUES_GVBS') > -1 && this.user?.organisationRole.indexOf('ORG_OWNER')) ||
        this.licenseIssue.indexOf('DAMAGE_COSTS') > -1 ||
        this.licenseIssue.indexOf('BUILDING_INSURANCE_VALUES') > -1)
    );
  }

  isDefined(value: any) {
    return typeof value !== 'undefined';
  }

  routeToCalculatorform(routeString: string) {
    // this.router.navigate(['process-form/input"'])
    this.router.navigate([routeString], {
      replaceUrl: true,
    });
  }

  onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
}
