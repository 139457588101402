import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { I18nModule } from '@app/i18n';
import { MaterialModule } from '@app/material.module';
import { AuthModule } from '@app/auth';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from '@app/shell/footer/footer.component';
import { LandingPageModule } from '@app/landing-page/landing-page.module';
import { SharedModule } from '@shared';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    MaterialModule,
    AuthModule,
    I18nModule,
    RouterModule,
    LandingPageModule,
    SharedModule,
  ],
  declarations: [HeaderComponent, FooterComponent],
  exports: [HeaderComponent, FooterComponent],
})
/**
 * Shell is used for the base layout of the page.
 * @since 24.06.2022
 */
export class ShellModule {}
