import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { RegistrationComponent } from './registration.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  { path: '7tage', component: RegistrationComponent, data: { title: 'register.title' } },
  { path: 'register', component: RegistrationComponent, data: { title: 'register.title' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes), TranslocoModule],
  exports: [RouterModule],
  providers: [],
})
export class RegistrationRoutingModule {}
