import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Logger } from '@shared';
import { CredentialsService } from './credentials.service';
import { AuthenticationService } from '@app/auth/authentication.service';
import { firstValueFrom, Observable, of } from 'rxjs';
import { LoginComponent } from '@app/auth/login.component';
import { MatDialog } from '@angular/material/dialog';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class LoginRequiredGuard {
  private router = inject(Router);
  private credentialsService = inject(CredentialsService);
  private authenticationService = inject(AuthenticationService);
  private dialog = inject(MatDialog);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.credentialsService.isAuthenticated()) {
      console.log('User is authenticated');
      return of(true);
    } else {
      console.log('User is not authenticated');
      this.openDialog();
      return of(true);
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '600px',
      closeOnNavigation: true,
    });

    firstValueFrom(dialogRef.afterClosed()).then((result) => {
      if (!this.credentialsService.isAuthenticated()) {
        this.router.navigate(['/'], { replaceUrl: true });
      }
    });
  }
}
